var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "配送单详情",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "custom-class": "dialog",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCancel } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "container",
        },
        [
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("配送通道")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.sPlatform)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("运单号")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.cSys)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("骑手姓名")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.sRider)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("手机号码")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.cRiderPhone)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("配送费")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v("￥" + _vm._s(_vm.ordFreightDetail?.nDelivery)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("运单调度费")]),
              _c("div", { staticClass: "content" }, [
                _vm._v("￥" + _vm._s(_vm.ordFreightDetail?.nDispatch)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("配送合计")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v("￥" + _vm._s(_vm.ordFreightDetail?.nTotal)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("配送状态")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(
                  _vm._s(
                    _vm.freightStatusMap[_vm.ordFreightDetail?.bStatus ?? 1]
                  )
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "itemContent",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item1",
                  staticStyle: { "margin-right": "20px" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("订单渠道")]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.ordFreightDetail?.sChannel)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "item1" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单号")]),
                _c("div", { staticClass: "content blue" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.cOrderSys)),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("店铺名称")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.sShop)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("配送门店")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.sStore)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("收货人")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.cCustomerName)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("隐私号码")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.cCustomerPhone)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("收货地址")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.cCustomerAddr)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("商家备注")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.cRemark)),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "itemContent",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item1",
                  staticStyle: { "margin-right": "20px" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.ordFreightDetail?.tCreate)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "item1" }, [
                _c("div", { staticClass: "title" }, [_vm._v("接单时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.tReceive)),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("到店时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.tArrive)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item1" }, [
              _c("div", { staticClass: "title" }, [_vm._v("完成时间")]),
              _c("div", { staticClass: "content blue" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.tComplete)),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "itemContent",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item2",
                  staticStyle: { "margin-right": "20px" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("接单时长")]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.ordFreightDetail?.fReceive)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item2",
                  staticStyle: { "margin-right": "20px" },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("到店时长")]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.ordFreightDetail?.fArrive)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "item2" }, [
                _c("div", { staticClass: "title" }, [_vm._v("配送时长")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.fDelivery)),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item2", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("配送距离")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.ordFreightDetail?.nDistance)),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "item2", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("是否超时")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(
                    _vm._s(_vm.timeoutMap[_vm.ordFreightDetail?.bTimeout ?? 0])
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "item2" }, [
              _c("div", { staticClass: "title" }, [_vm._v("超时时长")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.ordFreightDetail?.fTimeout)),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }