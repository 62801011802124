var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "自配送发单",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "custom-class": "dialog",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "系统单号", prop: "cSys" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.cSys))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拣货单号", prop: "rSys" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "270px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.rSys,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rSys", $$v)
                              },
                              expression: "form.rSys",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "link",
                              on: { click: _vm.openSelectOrdPicking },
                            },
                            [_vm._v("选择")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货人", prop: "cCustomerName" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cCustomerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cCustomerName", $$v)
                              },
                              expression: "form.cCustomerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "手机号码", prop: "cCustomerPhone" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cCustomerPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cCustomerPhone", $$v)
                              },
                              expression: "form.cCustomerPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货地址", prop: "cCustomerAddr" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cCustomerAddr,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cCustomerAddr", $$v)
                              },
                              expression: "form.cCustomerAddr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { gutter: 15 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "骑手姓名", prop: "riderName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "270px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.riderName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "riderName", $$v)
                              },
                              expression: "form.riderName",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "link",
                              on: { click: _vm.openSelectRider },
                            },
                            [_vm._v("选择")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "骑手电话", prop: "riderPhone" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.riderPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "riderPhone", $$v)
                              },
                              expression: "form.riderPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配送运费", prop: "nDelivery" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              precision: 2,
                              step: 1,
                              min: 0,
                              max: 10000,
                            },
                            model: {
                              value: _vm.form.nDelivery,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nDelivery", $$v)
                              },
                              expression: "form.nDelivery",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发单时间", prop: "tCreate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                            },
                            model: {
                              value: _vm.form.tCreate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tCreate", $$v)
                              },
                              expression: "form.tCreate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店", prop: "sStore" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sStore,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sStore", $$v)
                              },
                              expression: "form.sStore",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "事项(备注)", prop: "cRemark" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.cRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cRemark", $$v)
                              },
                              expression: "form.cRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectOrdPicking", {
        attrs: { visible: _vm.showSelectOrdPicking },
        on: {
          "update:visible": function ($event) {
            _vm.showSelectOrdPicking = $event
          },
          selectOrdPicking: _vm.selectOrdPicking,
        },
      }),
      _c("SelectRider", {
        attrs: { visible: _vm.showSelectRider, "r-store": _vm.form.rStore },
        on: {
          "update:visible": function ($event) {
            _vm.showSelectRider = $event
          },
          selectRider: _vm.selectedRider,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }